<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Story</h3>
      <!--      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>-->
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
          <label class="block mb-2">Description</label>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left">
            <div class="w-full mb-5">
              <vs-textarea
                :success="
                !errors.first('instruction') && questionData.instruction != ''
              "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('instruction') ? true : false"
                v-validate="'required'"
                name="instruction"
                data-vv-as="instruction"
                icon-no-border
                icon="icon icon-book"
                icon-pack="feather"
                class="w-full"
                v-model="questionData.instruction"
              />
              <span class="text-danger text-sm">{{ errors.first("instruction") }}</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <label class="block mb-3">Answer Type</label>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
            <div class="w-full mr-5">
              <vs-checkbox v-model="questionData.answerType" vs-value="text">Text</vs-checkbox>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
            <div class="w-full mr-5">
              <vs-checkbox v-model="questionData.answerType" vs-value="image">Image</vs-checkbox>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button
        :disabled="!validateForm"
        @click="submitHandler"
        class="mr-6"
      >Submit
      </vs-button>
      <vs-button class="vs-button-secondary" @click="cancelHandler()" align="right">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import { mapActions } from "vuex";

  export default {
    components: {
      VuePerfectScrollbar
    },
    data() {
      return {
        questionData: {
          instruction:"",
          answerType: [],
          childStorySetId: ""
        },
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
        id:"",
        instruction: "",
        answerType: [],
        childStorySetId: "",
        textDisabled: false,
        imageDisabled: false,
        textType: false,
        imageType: false,
        setId: ""
      }
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        },
      },
      dataId: {
        type: String,
        default : ""
      },
      storySetId: {
        type: String,
        default : ""
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          this.id = this.dataId
          this.questionData.answerType = this.data.answerType
          this.questionData.instruction = this.data.instruction
          this.initValues()
        }
        this.questionData.childStorySetId = this.storySetId;
        this.setId = this.storySetId;
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      validateForm() {
        return (
          !this.errors.any() &&
          this.questionData.instruction !="" &&
          this.questionData.answerType !="" &&
          this.questionData.childStorySetId !=""
        );
      },
    },
    methods: {
      ...mapActions("childrenStories", [
        "saveChildrenStory",
        "editChildrenStory"
      ]),
      initValues() {
        if (this.id) return
        this.id = ""
        this.answerType = ""
        this.instruction = ""
        this.setId = ""
      },
      async submitHandler() {
        this.$vs.loading();
        if (this.dataId !== "") {
          const info = {
            id: this.data._id,
            details: this.questionData
          };
          await this.editChildrenStory(info).then(res => {
            this.$vs.notify({
              title: 'Success',
              text: "Question edited Successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
            this.$vs.loading.close();
            this.questionData.instruction = "";
            this.questionData.answerType = [];
            this.$emit('refreshData', true);
          })
            .catch(err => {
              this.$vs.notify({
                title: 'Failure',
                text: "Question could not be edited.",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            })
        } else {
         await this.saveChildrenStory(this.questionData).then(res => {
            this.$vs.notify({
              title: 'Success',
              text: "Question created Successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
            this.$vs.loading.close();
            this.questionData.instruction = "";
            this.questionData.answerType = [];
            this.questionData.childStorySetId = "";
            this.$emit('refreshData', true);
          })
            .catch(err => {
              this.$vs.notify({
                title: 'Failure',
                text: "Question could not be created.",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              });
            })
        }
        this.$emit('closeSidebar');
        this.initValues();
      },
      cancelHandler() {
        this.isSidebarActiveLocal = false;

      },
    },
    mounted() {
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.id = this.dataId
        this.questionData.answerType = this.data.answerType
        this.questionData.instruction = this.data.instruction
        this.initValues()
      }
      this.questionData.childStorySetId = this.storySetId;
      this.setId = this.storySetId;
    },
    beforeMount() {
      this.questionData.instruction ="";
      this.questionData.answerType =[];
      this.questionData.childStorySetId = "";
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
  }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
